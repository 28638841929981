@import "assets/scss/_responsive";

.value-section-container {
  .value-section-content {
    display: flex;
    justify-content: space-between;
    position: relative;
    .icon {
      position: absolute;
      top: -20px;
      left: -20px;
    }
    h1 {
      font-weight: bold;
      font-size: clamp(1.6rem, 3.5vw, 3rem);
    }

    .content {
      width: 80%;
      padding-bottom: 10vh;
      @include mobile {
        width: 70%;
        padding-bottom: 5vh;
      }
      h3 {
        margin: 0px;
        margin-bottom: 2vh;
        font-size: clamp(1.2rem, 2.5vw, 1.7rem);
      }
      p {
        font-weight: 300;
        font-size: clamp(1.1rem, 1.5vw, 1.4rem);
        color: #7d7d7d;
        line-height: 1.3;
      }
    }
  }
}
