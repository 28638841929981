@import "assets/scss/_responsive";

#service-process-section {
  .reverse-row {
    flex-direction: row-reverse;
  }
  .introduction-row {
    padding: 16px 0px;
    margin-bottom: 20vh;
    justify-content: space-between;
    align-items: center;
    .motion-div {
      padding: 24px 0px;
      div {
        display: flex;
        align-items: center;
      }
    }
    .motion-image {
      display: flex;
      justify-content: center;
    }
  }
  .service-process {
    position: relative;
    .header {
      display: flex;
      align-items: center;
      .check-icon {
        width: 32px;
        height: 32px;
      }
    }
    @include mobile {
      .header {
        .check-icon {
          width: 16px;
          height: 16px;
        }
      }
      ul {
        max-width: 100%;
      }
    }
    h2 {
      margin: 0;
      padding-left: 8px;
      padding-bottom: 0px;
      color: #464646;
      font-size: var(--font-size-md);
      font-weight: bold;

      display: block;
    }

    p {
      margin-top: 16px;
      color: #7d7d7d;
      line-height: 1.5;
      font-size: var(--font-size-sm);
    }
    ul {
      width: 100%;
      max-width: 30%;
      line-height: 1.5;
      font-size: var(--font-size-sm);

      li {
        color: #7d7d7d;
      }
    }
    .image {
      width: 100%;
      border-radius: 3%;
    }
    @include tablet {
      display: block;
      p {
        max-width: 100%;
      }
      ul {
        max-width: 100%;
        line-height: 2;
      }
    }
    @include mobile {
      display: block;
      p {
        max-width: 100%;
        line-height: 2;
      }
      ul {
        max-width: 100%;
        line-height: 2.5;
      }
    }
  }
}
