@import "assets/scss/_responsive";

.nav-logo {
  position: fixed;
  top: 24px;
  left: 24px;
  width: 150px;
  z-index: 1;
}

.hidden {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0vw;
  z-index: 10;
  transition: width 0.1s;
  z-index: 20;
}
nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;
  z-index: 20;
  @include mobile {
    width: 300px;
  }
  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 350px;
    @include mobile {
      width: 300px;
    }
    background: #1f1f47;
  }
  .close-menu {
    display: none;
  }
  .open-menu {
    display: block;
  }

  button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 17px;
    left: calc(100% - 66px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
    /* z-index: 10; */
  }
  button:focus {
    outline: none;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
    padding: 25px;
    position: absolute;
    top: 100px;
    width: 300px;
    z-index: 20;
    @include mobile {
      width: 250px;
    }
  }

  li {
    list-style: none;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .menu-item {
    font-weight: bold;
    font-size: 1.75rem;
    color: #8f8fa3;
    text-decoration: none;
    :hover {
      text-decoration: none;
    }
  }
  .icon-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex: 40px 0;
    margin-right: 20px;
  }

  .text-placeholder {
    border-radius: 5px;
    width: 200px;
    height: 20px;
    flex: 1;
    font-size: 1.6rem;
  }

  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
