@import "assets/scss/_responsive";

:root {
  --font-size-lg: clamp(2rem, 3.5vw, 3rem);
  --font-size-md: clamp(1.5rem, 2.5vw, 1.7rem);
  --font-size-sm: clamp(1.1rem, 1.5vw, 1.4rem);
  --font-size-xs: clamp(1rem, 1vw, 1.2rem);
}
.about-company-container {
  h1 {
    margin: 0;
    font-weight: bold;
    font-size: var(--font-size-lg);
    color: #464646;
    line-height: 1.2;
  }
  h3 {
    margin-top: 64px;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: var(--font-size-md);
    width: 85%;
    max-width: 920px;
    color: #464646;
    line-height: 1.2;

    @include mobile {
      margin-top: 32px;
    }
  }
  .parallax {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../assets/img/custom/background/parallax-bg-2.webp") !important;
  }
  .banner {
    display: flex;
    align-items: center;
    height: 100vh;
    background: url("../../assets/img/custom/background/banner-background.svg")
      no-repeat;
    background-size: cover;
    @include tablet-vertical {
      padding: 25vh 0;
    }
    @include tablet {
      padding: 23vh 0;
    }
    @include mobile {
      padding: 20vh 0;
    }
    p {
      width: 85%;
      max-width: 920px;
      color: #7d7d7d;
      font-weight: 300;
      font-size: var(--font-size-sm);
      line-height: 1.5;
    }
  }
  .dark-banner {
    @extend .banner;
    background: url("../../assets/img/custom/background/banner-background-dark.svg")
      no-repeat;
    background-size: cover;
  }
  .content {
    padding-bottom: 20vh;
    @include mobile {
      padding-bottom: 10vh;
    }
    .desc-flex-box {
      display: flex;
    }
    .content-title {
      text-align: center;
      padding: 15vh 0px;
    }
    .desc {
      font-size: var(--font-size-xs);
      color: #7d7d7d;
      display: inline-block;
    }
  }
  .dark-section {
    background-color: #3e4756;
    h1 {
      color: #fff;
    }
    h3 {
      color: #fff;
    }
    p {
      color: #b5b5b5;
    }
  }
  .title-container {
    max-width: 1400px;
  }
}
