@import "assets/scss/_responsive";

.contact-us-container {
  h1 {
    margin: 0;
    font-weight: bold;
    font-size: var(--font-size-lg);
    color: #464646;
    line-height: 1.2;
  }
  h3 {
    margin-top: 64px;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: var(--font-size-md);
    width: 85%;
    max-width: 920px;
    color: #464646;
    line-height: 1.2;

    @include mobile {
      margin-top: 32px;
    }
  }
  .banner {
    display: flex;
    align-items: center;
    height: 100vh;
    background: url("../../assets/img/custom/background/banner-background.svg")
      no-repeat;
    background-size: cover;
    @include tablet-vertical {
      padding: 25vh 0;
    }
    @include tablet {
      padding: 23vh 0;
    }
    @include mobile {
      padding: 20vh 0;
    }
    p {
      width: 85%;
      max-width: 920px;
      color: #919191;
      font-weight: 300;
      font-size: var(--font-size-sm);
      line-height: 1.5;
    }
  }
  #contact-form {
    .message-input:focus {
      border: 1px solid #006bad;
    }
    .submit {
      margin-top: 5vh;
      button {
        background-color: #1f1f47;
        font-size: 16px;
        .spinner {
          margin-right: 8px;
          .visually-hidden {
            display: none;
          }
        }
      }
    }
  }
  .contact-us-page {
    .content-title {
      width: 100%;
      text-align: center;
      padding: 15vh 0px;
    }
    .form-grid-label {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 10vh;
      padding-bottom: 18px;
      h3 {
        margin: 0px;
        margin-right: 16px;
        width: auto;
      }
      p {
        width: auto;
        font-weight: 300;
        font-size: var(--font-size-xs);
        line-height: 1.3;
        color: #919191;
      }
    }
    .form-grid-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .input-group-focus:focus {
        border-color: #006bad;
      }

      .input-group {
        input {
          max-height: 38.5px;
        }
        width: 49.5%;
        @include mobile {
          width: 100%;
        }
      }
      .form-select {
        width: 100%;
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        padding: 10px 19px 11px 16px;
        color: #495057;
      }
      .form-select:focus {
        border: 1px solid #006bad;
      }
    }
    .contact-section {
      .contact-info-box {
        .contact-info {
          display: flex;
          .description {
            display: flex;
          }
        }
        .now-ui-icons {
          padding-right: 16px;
          font-size: 2em;
          color: #006bad;
        }
        h3 {
          margin: 0;
          width: auto;
          margin-right: 16px;
        }
        p {
          width: auto;
          font-weight: 400;
          font-size: var(--font-size-xs);
          line-height: 1.3;
        }
      }
    }
  }
  .input-group > .custom-select:not(:last-child),
  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}
