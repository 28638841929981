/*반응형 크기 정의*/
$tablet: 600px;
$tablet-vertical: 900px;
$desktop: 1200px;
$big-desktop: 1800px;

// tablet 이하
@mixin mobile {
  @media (max-width: $tablet) {
    @content;
  }
} // tablet 이상 tablet-vertical 이하
@mixin tablet {
  @media (min-width: ($tablet + 1)) and (max-width: $tablet-vertical) {
    @content;
  }
}
// tablet-vertical 이상 desktop 이하
@mixin tablet-vertical {
  @media (min-width: ($tablet-vertical + 1)) and (max-width: $desktop) {
    @content;
  }
}
// 크기가 desktop 이상
@mixin desktop {
  @media (min-width: ( $desktop + 1)) and (max-width: $big-desktop) {
    @content;
  }
}
// 크기가 big-desktop 이상
@mixin big-desktop {
  @media (min-width: $big-desktop) {
    @content;
  }
}
