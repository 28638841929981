@import "assets/scss/_responsive";
:root {
  --font-size-lg: clamp(1.6rem, 3.5vw, 3rem);
  --font-size-md: clamp(1rem, 2.5vw, 1.7rem);
  --font-size-sm: clamp(0.8rem, 1.5vw, 1.2rem);
  --font-size-xs: clamp(0.7rem, 1vw, 1rem);
}
#work-process-section {
  hr {
    margin: 8px 0px;
  }
  .work-process {
    border-bottom: 1px solid #ddd;
    display: flex;
    position: relative;
    padding-bottom: 15vh;
    margin-bottom: 15vh;
    @include mobile {
      padding-bottom: 10vh;
      margin-bottom: 10vh;
    }
    .header {
      width: 100%;
      max-width: 25%;
      min-width: 200px;
      display: flex;
      .check-icon {
        width: 32px;
        height: 32px;
      }
    }
    @include mobile {
      display: block;
      .header {
        max-width: 100%;
        padding-bottom: 16px;
        align-items: center;

        .check-icon {
          width: 24px;
          height: 24px;
        }
      }
      ul {
        max-width: 100%;
      }
    }
    h2 {
      margin: 0;
      padding-left: 8px;
      color: #464646;
      font-size: var(--font-size-md);
      font-weight: bold;

      display: block;
    }
    .work-desc {
      display: flex;

      p {
        width: 100%;
        max-width: 70%;
        padding-right: 5vh;
        color: #7d7d7d;
        line-height: 1.5;
        font-size: var(--font-size-sm);
      }
      ul {
        width: 100%;
        max-width: 30%;
        line-height: 1.5;

        li {
          color: #7d7d7d;
          font-size: var(--font-size-xs);
        }
      }
      @include tablet {
        display: block;
        p {
          max-width: 100%;
        }
        ul {
          max-width: 100%;
          line-height: 2;
        }
      }
      @include mobile {
        display: block;
        p {
          max-width: 100%;
          line-height: 1.7;
        }
        ul {
          max-width: 100%;
          line-height: 2.5;
        }
      }
    }
  }
}
