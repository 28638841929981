// @font-face {
//   font-family: "yg-jalnan";
//   src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff")
//     format("woff");

//   // src: url("./assets/fonts/Jalnan.woff") format("woff")
//   //   url("./assets/fonts/Jalnan.woff2") format("woff2");
//   // font-weight: normal;
//   // font-style: normal;
// }
@font-face {
  font-family: "Noto Sans Korean";
  font-style: normal;
  font-weight: 300;
  src: url(//script.ebay.co.kr/fonts/NotoSansKR-Light-Hestia.eot),
    url(//script.ebay.co.kr/fonts/NotoSansKR-Light-Hestia.eot?#iefix)
      format("embedded-opentype");
  src: local("Noto Sans Light"), local("NotoSans-Light"),
    url(//script.ebay.co.kr/fonts/NotoSansKR-Light-Hestia.woff) format("woff"),
    url(//script.ebay.co.kr/fonts/NotoSansKR-Light-Hestia.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-style: normal;
  font-weight: 500;
  src: url(//script.ebay.co.kr/fonts/NotoSansKR-DemiLight-Hestia.eot),
    url(//script.ebay.co.kr/fonts/NotoSansKR-DemiLight-Hestia.eot?#iefix)
      format("embedded-opentype");
  src: local("Noto Sans DemiLight"), local("NotoSans-DemiLight"),
    url(//script.ebay.co.kr/fonts/NotoSansKR-DemiLight-Hestia.woff)
      format("woff"),
    url(//script.ebay.co.kr/fonts/NotoSansKR-DemiLight-Hestia.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-style: normal;
  font-weight: bold;
  src: url(//script.ebay.co.kr/fonts/NotoSansKR-Medium-Hestia.eot),
    url(//script.ebay.co.kr/fonts/NotoSansKR-Medium-Hestia.eot?#iefix)
      format("embedded-opentype");
  src: local("Noto Sans Medium"), local("NotoSans-Medium"),
    url(//script.ebay.co.kr/fonts/NotoSansKR-Medium-Hestia.woff) format("woff"),
    url(//script.ebay.co.kr/fonts/NotoSansKR-Medium-Hestia.otf)
      format("opentype");
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
blockquote,
big,
body,
center,
canvas,
caption,
cite,
code,
command,
datalist,
dd,
del,
details,
dfn,
dl,
div,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
keygen,
label,
legend,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
span,
source,
strike,
strong,
sub,
sup,
table,
tbody,
tfoot,
thead,
th,
tr,
tdvideo,
tt,
u,
ul,
var,
li {
  font-family: "Noto Sans Korean", "Open Sans", "malgun gothic", "Nanum Gothic",
    dotum, arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}
// * {
//   font-family: "yg-jalnan" !important;
// }
body {
  .layout {
    width: 100%;
    overflow-x: hidden;
  }
}
