.reverse-row {
  flex-direction: row-reverse;
}

.history-row {
  padding: 16px 0px;
  .header-motion-div {
    padding: 32px 0px;
  }
  .header {
    margin: 0;
    color: #ffffff;
  }
  .desc-motion-div {
    padding: 24px 0px;
    border-top: 1px solid #ddd;
  }
  .desc {
    color: #ffffff !important;
    line-height: 1.2;
  }
}
