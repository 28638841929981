.main-page-wrapper {
  font-family: "Nunito", sans-serif;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  .background {
    position: absolute;
    background: #1f1f47;
    min-height: 100vh;
    width: 100vw;
  }
  .main-title {
    font-size: 80px;
    color: #ffffff;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .card {
    width: 100%;
    min-height: 250px;
    background: transparent;
    padding: 1.5rem;
    z-index: 14;
    color: whitesmoke;
    display: flex;
    align-items: center;
    box-shadow: none;
    .logo {
      width: 30%;
      min-width: 300px;
    }
  }

  .title {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }

  .subtitle {
    font-size: 1rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    line-height: 1.5;
    text-align: center;
  }

  .btn {
    background: none;
    border: none;
    text-align: center;
    font-size: 1rem;
    color: whitesmoke;
    background-color: transparent;
    padding: 0.8rem 1.8rem;
    border-radius: 2rem;
    border: 1px solid whitesmoke;
    cursor: pointer;
  }
  .menu {
    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: 15;
    .menu-item {
      font-size: clamp(1.5rem, 3.5vw, 2rem);
      padding-bottom: 24px;
      display: flex;
      justify-content: end;
      align-items: center;
      list-style: none;
      cursor: pointer;
      a {
        color: #ffffff;
        text-decoration: none;
        :hover {
          text-decoration: none;
        }
      }
      .text-placeholder {
        a {
          font-size: clamp(1.5rem, 3.5vw, 2rem);
        }
      }
      .icon-placeholder {
        width: 0.6rem;
        height: 0.6rem;
        background-color: whitesmoke;
        border-radius: 5px;
        margin-left: 8px;
      }
    }
  }
  .blob {
    z-index: 12;
    position: absolute;
    width: 500px;
    height: 500px;
    background: linear-gradient(180deg, rgba(47, 184, 255, 0.42), #5c9df1 100%);
    mix-blend-mode: color-dodge;
    -webkit-animation: move 25s infinite alternate;
    animation: move 25s infinite alternate;
    transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
  }

  .blob:hover {
    width: 520px;
    height: 520px;
    -webkit-filter: blur(30px);
    filter: blur(30px);
    box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.6),
      inset 100px 100px 0 0px #fa709a, inset 200px 200px 0 0px #784ba8,
      inset 300px 300px 0 0px #2b86c5;
  }
  .blob2 {
    z-index: 13;
    @extend .blob;
    background: linear-gradient(
      180deg,
      rgba(186, 117, 255, 0.49),
      #3913b8 100%
    );
    animation: move2 30s infinite alternate;
  }
  .wave {
    position: absolute;
    width: 100%;
    height: 1000px;
    top: 0px;
    left: 1%;
    background-image: url(../../assets/img/custom/background/round-wave.svg);
    background-position: 0 top;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 10;

    @media (max-width: 1440px) {
      background-size: 1440px;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }
  .wave2 {
    position: absolute;
    width: 100%;
    height: 600px;
    top: 450px;
    background: linear-gradient(
      180deg,
      rgba(31, 31, 71, 0.25) -18.72%,
      #1f1f47 37.6%
    );
    clip-path: url(#wave4);
    transform-origin: top left;
    overflow: hidden;
    z-index: 11;

    @media (min-width: 1440px) {
      transform: scale(1.5);
    }

    @media (min-width: 2200px) {
      transform: scale(2);
    }

    @media (min-width: 2600px) {
      transform: scale(2.1);
    }
  }

  @-webkit-keyframes move {
    from {
      transform: translate(0px, -50px) rotate(-90deg);
      border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
    }

    to {
      transform: translate(500px, 100px) rotate(-10deg);
      border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
    }
  }

  @keyframes move {
    from {
      transform: translate(0px, -50px) rotate(-90deg);
      border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
    }

    to {
      transform: translate(500px, 100px) rotate(-10deg);
      border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
    }
  }
  @-webkit-keyframes move2 {
    from {
      transform: translate(-200px, 0px) rotate(-90deg);
      border-radius: 51% 49% 58% 42% / 34% 78% 22% 66%;
    }

    to {
      transform: translate(400px, 100px) rotate(-120deg);
      border-radius: 22% 78% 73% 27% / 67% 31% 69% 33%;
    }
  }

  @keyframes move2 {
    from {
      transform: translate(-200px, 0px) rotate(-90deg);
      border-radius: 51% 49% 58% 42% / 34% 78% 22% 66%;
    }

    to {
      transform: translate(400px, 100px) rotate(-120deg);
      border-radius: 22% 78% 73% 27% / 67% 31% 69% 33%;
    }
  }
}
