@import "assets/scss/_responsive";

.parallax-section {
  @include mobile {
    display: none;
  }
  width: 100%;
  height: 50vh;
  position: relative;
  .bg-wrap {
    clip: rect(0, auto, auto, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .bg {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../../assets/img/custom/background/parallax-bg.webp");
  }
}
