@import "assets/scss/_responsive";

.certificate-section-wrapper {
  .swiper {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    @include mobile {
      width: 100%;
    }
  }

  .swiper-slide {
    margin-bottom: 24px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-end;
  }

  .swiper-slide img {
    display: block;
    width: 25%;
    min-width: 200px;
    object-fit: cover;
    padding-right: 24px;
  }
  .certificate-wrapper {
    margin: 16px 0px;

    @include mobile {
      width: 40%;
    }
    h1 {
      margin: 0;
      font-weight: bold;
      font-size: var(--font-size-lg);
      color: #464646;
      line-height: 1.2;
    }
    h3 {
      width: auto;
      font-weight: bold;
      font-size: var(--font-size-md);
      color: #464646;
      line-height: 1.2;
      margin: 0;
      margin-bottom: 16px;
    }
    p {
      color: #7d7d7d;
      font-weight: 500;
      font-size: var(--font-size-sm);
      line-height: 1.3;
    }
    .certificate-title {
      text-align: left;
      margin-bottom: 15vh;
    }
    .certificate-index {
      h3 {
        margin: 0;
        margin-right: 8px;
      }
      p {
        color: #fff;
        -webkit-text-stroke: 1px #666;
      }
      display: flex;
      align-items: flex-end;
    }
  }
}
