@import "assets/scss/_responsive";

.corporation-row {
  background-color: white;
  margin-left: 7%;
  margin-right: 7%;
  margin-top: 2%;

  .corp-list {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
    @include mobile {
      justify-content: center;
    }
    .image-wrapper {
      margin: 16px 0px;
      @include mobile {
        height: auto;
        width: 220px;
      }
      .corp-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
