@import "assets/scss/_responsive";

:root {
  --font-size-lg: clamp(2rem, 3.5vw, 3rem);
  --font-size-md: clamp(1.5rem, 2.5vw, 1.7rem);
  --font-size-sm: clamp(1.1rem, 1.5vw, 1.4rem);
  --font-size-xs: clamp(1rem, 1vw, 1.2rem);
}

.company-info-section-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .company-info-row {
    padding: 16px 0px;
    .company-info {
      @include mobile {
        margin-bottom: 16px;
      }
    }
    .header-motion-div {
      padding: 24px 0px;
      @include mobile {
        padding: 16px 0px;
      }
    }
    .header {
      margin: 0;
      color: #222222;
      font-size: var(--font-size-md);
    }
    .desc-motion-div {
      padding: 16px 0px;
      border-top: 1px solid #ddd;
    }
    .desc {
      font-size: var(--font-size-xs);
      color: #707070;
      line-height: 1.4;
    }
  }
}
