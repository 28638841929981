@import "assets/scss/_responsive";

:root {
  --font-size-xs: clamp(0.6rem, 1.5vw, 0.9rem);
}
.footer {
  background-color: #222222;
  font-size: var(--font-size-xs);
  ul {
    display: flex;
    margin-left: -15px;
    a {
      text-decoration: none;
      color: #919191;
    }
  }
  li {
    padding: 8px 16px;
    line-height: normal;
  }
  hr {
    margin: 16px 0px;
    border-top: 1px solid #464646;
  }
  .company-info {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;

    span {
      margin-right: 40px;
      color: #919191;
      line-height: 2;
      @include mobile {
        margin-right: 16px;
        text-align: left;
      }
    }
  }
  p {
    margin: 16px 0px;
    color: #919191;
    font-size: var(--font-size-xs);
    text-align: left;
  }
}
