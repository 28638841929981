@import "assets/scss/_responsive";

#introduction-section {
  .reverse-row {
    flex-direction: row-reverse;
  }
  .introduction-row {
    padding: 16px 0px;
    margin-bottom: 20vh;
    justify-content: space-between;
    align-items: center;
    @include mobile {
      margin-bottom: 10vh;
    }
    .motion-div {
      padding: 24px 0px;
      div {
        display: flex;
        align-items: center;
      }
    }
    .header {
      margin: 0;
      color: #464646;
      font-size: var(--font-size-md);
      font-weight: bold;
    }
    .desc-br {
      margin: 16px 0px;
      border-top: 1px solid #ddd;
    }
    .desc {
      font-size: var(--font-size-xs);
      color: #7d7d7d;
      margin-bottom: 0px !important;
      margin-left: 8px;
      display: inline-block;
      width: 99%;
      line-height: 1.4;
    }
    .image {
      width: 100%;
      border-radius: 3%;
    }
  }
}
